import React from "react";
import { graphql } from "gatsby";
import Author from "../components/Author";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PostCard from "../components/PostCard";

class Posts extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const siteDescription = data.site.siteMetadata.description;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        showLogo={false}
        showArchivesLink
      >
        <SEO title={`${siteTitle} — ${siteDescription}`} />
        <Author showTitle />

        <div className="posts">
          {posts.map(({ node }) => (
            <PostCard key={node.fields.slug} post={node} />
          ))}
        </div>
      </Layout>
    );
  }
}

export default Posts;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { archived: { ne: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D MMMM YYYY", locale: "FR-CA")
            title
          }
        }
      }
    }
  }
`;
